@import './common.scss';

section.about {
  min-height: 100vh;
  background-color: $bg-dark;
  z-index: 1;
  transform: translateZ(1px);
  color: $bg-dark-contrast;
  padding: 64px 16px;
  overflow-y: hidden;

  div.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas: 'image heading' 'image text';
    grid-gap: 24px 48px;

    img.about-image {
      grid-area: image;
      width: 100%;
      opacity: 0;
    }

    h2.about-heading {
      grid-area: heading;
      opacity: 0;
      margin-top: 3vw;
      padding: 1vw 0 0 4vw;
      background: linear-gradient(
        180deg,
        $bg-dark 72%,
        rgba(255, 255, 255, 0) 72%
      );
      width: calc(100% + 15vw);
      height: min-content;
      transform: translateX(-20vw);
    }

    div.about-text {
      grid-area: text;
      margin: 80px 0 -80px;
    }
  }

  @media screen and (max-width: 899px) {
    div.grid {
      grid-template-columns: 2fr 1fr;
      grid-template-rows: auto 1fr;
      grid-template-areas: 'image heading' 'text text';
      grid-gap: 32px;

      h2.about-heading {
        width: calc(100% + 20vw);
        transform: translateX(-5vw);
        justify-self: end;
        align-self: end;
      }
    }
  }

  @media screen and (max-width: 699px) {
    div.grid {
      grid-template-columns: 1fr;
      grid-template-rows: auto 0 1fr;
      grid-template-areas: 'image' 'heading' 'text';
      grid-gap: 16px;

      h2.about-heading {
        width: fit-content;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 35%,
          $bg-dark 35%,
          $bg-dark 100%,
          rgba(255, 255, 255, 0) 100%
        );
        transform: translateX(0) translateY(8px);
        padding: 1vw 2vw 0 4vw;
      }
    }
  }
}
