@import './common.scss';

section.landing::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: min(calc(-10vh - 24px), -64px);
  height: max(calc(10vh + 24px), 64px);
  background-color: $bg-dark;
  z-index: 1;
}

section.landing {
  background-color: $bg-light;
  position: relative;

  .background,
  .midground,
  .foreground {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    transition: transform 0.1s ease;
  }

  .background {
    margin-top: -5vh;

    .background-image {
      background-image: url(assets/images/background.svg);
      background-position: bottom;
      background-repeat: no-repeat;
      height: 100vh;
      margin-bottom: -1px;
    }

    .background-bottom {
      background-color: $primary-light;
      min-height: 100px;
      height: calc(60vh + 1px);
    }
  }

  .midground {
    margin-top: -8vh;

    .midground-image {
      background-image: url(assets/images/midground.svg);
      background-position: bottom;
      background-repeat: no-repeat;
      height: 100vh;
      margin-bottom: -1px;
    }

    .midground-bottom {
      background-color: $primary-dark;
      min-height: 100px;
      height: calc(40vh + 1px);
    }
  }

  .heading {
    position: absolute;
    top: 0;
    width: 100vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 18vh;
    opacity: 0;
    transition: transform 0.1s ease;
    transform-origin: center 25%;
    animation: reveal 1s ease-in-out 0.3s forwards;

    div {
      min-width: 66vw;
      width: min-content;
      padding-bottom: 300px;
    }

    h1 {
      font-size: 10vw;
      line-height: 1.1;
      margin: 0;
      font-weight: 600;
    }

    h2 {
      font-size: 1.2rem;
      margin: 1rem 0 0 0.5vw;
      font-weight: 500;
    }
  }

  .foreground {
    position: relative;
    height: 90vh;
    pointer-events: none;

    .foreground-image {
      background-image: url(assets/images/foreground.svg);
      background-position: bottom;
      background-repeat: no-repeat;
      height: 70vh;
      margin-bottom: -1px;
    }

    .foreground-bottom {
      background-color: $bg-dark;
      height: calc(20vh + 1px);
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: all;

      @keyframes scroll-down-animation {
        0% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(8px);
        }
        100% {
          transform: translateY(0);
        }
      }

      img.scroll-down-icon {
        width: 40px;
        animation: scroll-down-animation 3s ease-in-out infinite;
        padding: 4px 8px;
        cursor: pointer;
        fill: $bg-dark-contrast;
      }
    }
  }

  @media only screen and (min-width: 1000px) {
    .heading {
      h1 {
        font-size: 100px;
      }

      div {
        min-width: 660px;
      }
    }
  }

  @media only screen and (max-height: 749px) and (orientation: landscape) {
    .heading {
      h1 {
        font-size: 10vh;
      }

      div {
        min-width: 66vh;
        padding-bottom: 200px;
      }
    }
  }
}
