@import './common.scss';

section.contact {
  min-height: 80vh;
  background-color: $bg-light;
  z-index: 1;
  transform: translateZ(1px);
  color: $bg-light-contrast;
  padding: 64px 16px;

  div.grid {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-template-rows: auto auto auto 1fr;
    grid-template-areas: 'image socialmedia-heading' 'image socialmedia-text' 'image contact-heading' 'image contact-text';
    grid-gap: 24px 48px;

    img.contact-image {
      grid-area: image;
      width: 100%;
      z-index: -1;
      transform: scale(0.9);
      opacity: 0;
    }

    h3.socialmedia-heading {
      grid-area: socialmedia-heading;
      margin: -8% 0 -24px -41%;
    }

    div.socialmedia-text {
      grid-area: socialmedia-text;

      p {
        display: grid;
        align-items: center;
        grid-template-columns: 2.25rem max-content;

        img {
          height: 1.5rem;
        }
      }
    }

    h3.contact-heading {
      grid-area: contact-heading;
      margin-bottom: -24px;
    }

    div.contact-text {
      grid-area: contact-text;
    }
  }

  @media screen and (max-width: 769px) {
    div.grid {
      grid-template-columns: 100%;
      grid-template-rows: auto auto auto 1fr;
      grid-template-areas: 'image' 'socialmedia-heading' 'socialmedia-text' 'contact-heading' 'contact-text';
      grid-gap: 0;
      overflow-x: hidden;
      grid-gap: 0;

      h3.socialmedia-heading {
        width: fit-content;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 18%,
          $bg-light 18%,
          $bg-light 100%,
          rgba(255, 255, 255, 0) 100%
        );
        transform: translateX(0) translateY(-5.5vw);
        padding: 1vw 2vw 0 32px;
        margin: 0 0 8px;
      }

      div.socialmedia-text {
        margin: -32px 0 0 32px;
      }

      h3.contact-heading {
        transform: translateY(-5.5vw);
        padding: 10vw 2vw 0 32px;
      }

      div.contact-text {
        margin-left: 32px;
      }
    }
  }
}
