@import 'flickity/dist/flickity.min.css';
@import './common.scss';

section.videos {
  min-height: 100vh;
  background-color: $bg-light;
  z-index: 1;
  transform: translateZ(1px);
  color: $bg-light-contrast;
  padding: 64px 16px;

  div.grid {
    display: grid;
    grid-template-columns: calc(50% - 24px) calc(50% - 24px);
    grid-template-rows: auto auto auto 1fr;
    grid-template-areas: 'heading image' 'text image' 'mainplayer image' 'carousel carousel';
    grid-gap: 24px 48px;

    img.videos-image {
      grid-area: image;
      width: 100%;
    }

    h2.videos-heading {
      grid-area: heading;
      margin-top: 3vw;
      padding: 1vw 8vw 0 0;
      background: linear-gradient(
        180deg,
        $bg-light 72%,
        rgba(255, 255, 255, 0) 72%
      );
      width: calc(100%);
      height: min-content;
      transform: translateX(180px);
      opacity: 0;
    }

    div.videos-text {
      grid-area: text;
      opacity: 0;
    }

    div.videos-main-player {
      grid-area: mainplayer;
      width: calc(100% + 144px);
      margin: -70px 0 70px;
      opacity: 0;
    }

    .videos-carousel {
      grid-area: carousel;
      overflow-x: hidden;
      width: 100%;
      margin-top: 160px;

      .videos-carousel-cell {
        width: 80%;
        margin: 0 1vw;
      }
    }
  }

  .video {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    background: $grey;

    iframe {
      background-color: $grey;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 899px) {
    div.grid {
      grid-template-columns: calc(30% - 16px) calc(70% - 16px);
      grid-template-rows: auto auto auto 1fr;
      grid-template-areas: 'heading image' 'text text' 'mainplayer mainplayer' 'carousel carousel';
      grid-gap: 32px;

      h2.videos-heading {
        width: calc(100% + 25vw);
        transform: translateX(0);
        align-self: end;
      }

      div.videos-main-player {
        width: 100%;
        margin: -80px 0 80px;
      }
      .videos-carousel {
        margin-top: 160px;
      }
    }
  }

  @media screen and (max-width: 699px) {
    div.grid {
      grid-template-columns: 1fr;
      grid-template-rows: auto 0 auto auto 1fr;
      grid-template-areas: 'image' 'heading' 'text' 'mainplayer' 'carousel';
      grid-gap: 16px;

      h2.videos-heading {
        width: fit-content;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 5%,
          $bg-light 5%,
          $bg-light 100%,
          rgba(255, 255, 255, 0) 100%
        );
        transform: translateX(0) translateY(8px);
        padding: 1vw 4vw 0 0;
      }

      div.videos-text {
        margin-top: 16px;
      }

      div.videos-main-player {
        width: 100%;
        margin: -85px 0 85px;
      }
    }
  }
}
