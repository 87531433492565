@import './common.scss';

section.software {
  min-height: 100vh;
  background-color: $bg-dark;
  z-index: 1;
  transform: translateZ(1px);
  color: $bg-dark-contrast;
  padding: 64px 16px;
  position: relative;

  img.software-image {
    width: 30%;
    z-index: -1;
    position: absolute;
    top: 30%;
    left: 10%;
  }

  h2.software-heading-small {
    display: none;
  }

  div.grid {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto auto 1fr;
    grid-gap: 24px 48px;
    margin-top: 48px;

    h3,
    div {
      opacity: 0;
    }

    h3 {
      justify-self: end;
      margin-top: 8px;
    }
  }

  @media screen and (max-width: 699px) {
    h2.software-heading-small {
      display: inline-block;
    }

    h2.software-heading-large {
      display: none;
    }

    img.software-image {
      width: 60%;
    }

    div.grid {
      grid-template-columns: 100%;
      grid-gap: 16px;
      margin-top: 16px;

      h3 {
        justify-self: start;
        margin: 16px 0 -16px;
      }
    }
  }
}
