@import 'common.scss';

footer {
  padding-bottom: 32px;
  text-align: center;
  background-color: $bg-light;

  .copy {
    font-family: Arial, Helvetica, sans-serif;
  }
}
